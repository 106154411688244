import React, { useState, useRef, useEffect } from 'react';
import { Form, Button, Alert, Row, Col } from 'react-bootstrap';
import { FaLinkedin, FaTwitter } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import emailjs from '@emailjs/browser';
import PageLayout from '../components/PageLayout';
import './Contact.css';

function Contact() {
  const form = useRef();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
    honeypot: '',
    gdprConsent: false
  });
  const [errors, setErrors] = useState({});
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
    emailjs.init(process.env.REACT_APP_EMAILJS_PUBLIC_KEY);
  }, []);

  const handleChange = (e) => {
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    setFormData({ ...formData, [e.target.name]: value });
  };

  const validateForm = () => {
    let tempErrors = {};
    if (!formData.name.trim()) tempErrors.name = "Name is required";
    if (!formData.email.trim()) {
      tempErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      tempErrors.email = "Email is invalid";
    }
    if (!formData.message.trim()) tempErrors.message = "Message is required";
    if (!formData.gdprConsent) tempErrors.gdprConsent = "You must agree to the GDPR terms";
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validateForm() && !formData.honeypot) {
      emailjs.sendForm(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        form.current,
        process.env.REACT_APP_EMAILJS_PUBLIC_KEY
      )
      .then((result) => {
        setShowSuccess(true);
        setFormData({ name: '', email: '', message: '', honeypot: '', gdprConsent: false });
      }, (error) => {
        setShowError(true);
        setErrorMessage(JSON.stringify(error));
      });
    }
  };

  return (
    <PageLayout title="Contact Me">
      <div className={`contact ${isVisible ? 'is-visible' : ''}`}>
        {showSuccess && (
          <Alert variant="success" onClose={() => setShowSuccess(false)} dismissible className="fade-in">
            Your message has been sent successfully!
          </Alert>
        )}
        {showError && (
          <Alert variant="danger" onClose={() => setShowError(false)} dismissible className="fade-in">
            {errorMessage}
          </Alert>
        )}
        <Row>
          <Col md={10} className="slide-in-left">
            <Form ref={form} onSubmit={handleSubmit}>
              <Form.Group className="mb-3" controlId="formName">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  isInvalid={!!errors.name}
                />
                <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="mb-3" controlId="formEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  isInvalid={!!errors.email}
                />
                <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="mb-3" controlId="formMessage">
                <Form.Label>Message</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  isInvalid={!!errors.message}
                />
                <Form.Control.Feedback type="invalid">{errors.message}</Form.Control.Feedback>
              </Form.Group>

              <Form.Control
                type="text"
                name="honeypot"
                value={formData.honeypot}
                onChange={handleChange}
                style={{ display: 'none' }}
                tabIndex="-1"
                autoComplete="off"
              />

              <Form.Group className="mb-3" controlId="formGDPRConsent">
                <Form.Check
                  type="checkbox"
                  name="gdprConsent"
                  checked={formData.gdprConsent}
                  onChange={handleChange}
                  isInvalid={!!errors.gdprConsent}
                  label={
                    <span>
                      I have read and agree to the <Link to="/gdpr">GDPR terms</Link>
                    </span>
                  }
                />
                <Form.Control.Feedback type="invalid">{errors.gdprConsent}</Form.Control.Feedback>
              </Form.Group>

              <Button variant="primary" type="submit">
                Send Message
              </Button>
            </Form>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col md={10} className="slide-in-right">
            <div className="social-links">
              <h3 className="mb-3">Connect with me</h3>
              <div className="d-flex">
                <a href="https://www.linkedin.com/in/patrick-presslmair-64257b1ab/" target="_blank" rel="noopener noreferrer" className="me-3">
                  <FaLinkedin size={30} />
                </a>
                <a href="https://x.com/PikheatP" target="_blank" rel="noopener noreferrer">
                  <FaTwitter size={30} />
                </a>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </PageLayout>
  );
}

export default Contact;