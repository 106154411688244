import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const GlitchHeader = styled(Link)`
  position: relative;
  color: white;
  font-size: 150px;
  margin: 0;
  padding: 0;
  text-align: center;
  text-decoration: none;
  display: inline-block;

  @media (max-width: 768px) {
    font-size: 80px;
  }

  @media (max-width: 480px) {
    font-size: 60px;
  }

  &:hover {
    text-shadow: 0.05em 0 0 #ec2225, -0.025em -0.05em 0 #313f97,
      0.025em 0.05em 0 #50c878;
    color: rgba(0, 194, 203, 0.2);
  }

  &:before,
  &:after {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.8;
  }

  &:hover::before {
    animation: glitch 650ms infinite;
    clip-path: polygon(0 0, 100% 0, 100% 45%, 0 45%);
    transform: translate(-0.025em, -0.0125em);
  }

  &:hover::after {
    animation: glitch 375ms infinite;
    clip-path: polygon(0 65%, 100% 20%, 100% 100%, 0 70%);
    transform: translate(0.0125em, 0.025em);
  }

  @keyframes glitch {
    0% {
      color: rgba(236, 34, 37, 0.2);
      text-shadow: 0.05em 0 0 #ec2225, -0.025em -0.05em 0 #313f97,
        0.025em 0.05em 0 #50c878;
    }
    14% {
      text-shadow: 0.05em 0 0 #ec2225, -0.025em -0.05em 0 #313f97,
        0.025em 0.05em 0 #50c878;
    }
    15% {
      color: #50c878;
      text-shadow: -0.05em -0.025em 0 #ec2225, 0.025em -0.025em 0 #313f97,
        -0.05em -0.05em 0 #50c878;
    }
    49% {
      text-shadow: -0.05em -0.025em 0 #ec2225, 0.025em -0.025em 0 #313f97,
        -0.05em -0.05em 0 #50c878;
    }
    50% {
      text-shadow: 0.025em 0.05em 0 #ec2225, -0.025em 0.05em 0 #313f97,
        0 -0.05em 0 #50c878;
    }
    99% {
      color: #313f97;
      text-shadow: 0.025em 0.05em 0 #ec2225, -0.025em 0.05em 0 #313f97,
        0 -0.05em 0 #50c878;
    }
    100% {
      text-shadow: -0.025em 0 0 #ec2225, -0.025em -0.025em 0 #313f97,
        -0.025em -0.05em 0 #50c878;
    }
  }
`;

const HomeContainer = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 25vh;

  @media (max-width: 768px) {
    padding-top: 35vh;
  }

  @media (max-width: 480px) {
    padding-top: 30vh;
  }
`;

function Home() {
  return (
    <HomeContainer>
      <GlitchHeader to="/about" data-text="This is me">This is me</GlitchHeader>
    </HomeContainer>
  );
}

export default Home;