import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import PageLayout from '../components/PageLayout';

function encodeEmail(email) {
    return email.split('').map(char => `&#${char.charCodeAt(0)};`).join('');
  }
  

function Gdpr() {
  return (
    <PageLayout title="Privacy Policy and GDPR Compliance">
      <Row>
        <Col>
          <h2>1. Introduction</h2>
          <p>This privacy policy explains how we collect, use, and protect your personal data when you use our website. We are committed to ensuring your privacy and complying with the General Data Protection Regulation (GDPR).</p>

          <h2>2. Data Controller</h2>
          <p>Patrick Presslmair<br />
          Albert-Schöpf-Straße 12, 4020 Linz<br />
          <span dangerouslySetInnerHTML={{__html: `Email: ${encodeEmail('patrick@presslmair.at')}`}} /></p>


          <h2>3. Personal Data We Collect</h2>
          <p>We collect the following personal data through our contact form:</p>
          <ul>
            <li>Name</li>
            <li>Email address</li>
            <li>Message content</li>
          </ul>

          <h2>4. Purpose of Data Collection</h2>
          <p>We collect this data solely for the purpose of responding to your inquiries or messages submitted through our contact form.</p>

          <h2>5. Legal Basis for Processing</h2>
          <p>The legal basis for processing your personal data is your consent, which you provide by submitting the contact form.</p>

          <h2>6. Data Retention</h2>
          <p>We retain the data you submit through our contact form in our email system. We do not have a fixed retention period, but we will not keep your data longer than necessary for the purposes for which it was collected.</p>

          <h2>7. Data Storage and Security</h2>
          <p>All data is stored within the European Union. While we implement reasonable security measures, we do not have specific additional security measures in place for the data collected through the contact form beyond the standard email system security.</p>

          <h2>8. Third-Party Services</h2>
          <p>We use Google Fonts, which are locally hosted on our server. This means that your personal data is not sent to Google when you visit our website.</p>

          <h2>9. Your Rights</h2>
          <p>Under GDPR, you have the following rights:</p>
          <ul>
            <li>Right to access your personal data</li>
            <li>Right to rectification of inaccurate personal data</li>
            <li>Right to erasure of your personal data</li>
            <li>Right to restrict processing of your personal data</li>
            <li>Right to data portability</li>
            <li>Right to object to processing of your personal data</li>
          </ul>
          <p>To exercise these rights, please contact us using the contact information provided in section 2.</p>

          <h2>10. Data Breach Notification</h2>
          <p>In the event of a data breach that is likely to result in a risk to your rights and freedoms, we will notify you and the relevant supervisory authority without undue delay.</p>

          <h2>11. Changes to This Privacy Policy</h2>
          <p>We may update this privacy policy from time to time. Any changes will be posted on this page.</p>

          <h2>12. Contact Us</h2>
          <p>If you have any questions about this privacy policy or our data practices, please contact us using the information provided in section 2.</p>

          <p><em>Last updated: 11.07.2024</em></p>

          <div className="mt-4">
            <Link to="/contact">
              <Button variant="primary" size="lg">Contact Us</Button>
            </Link>
          </div>
        </Col>
      </Row>
    </PageLayout>
  );
}

export default Gdpr;