import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FaLinkedin, FaTwitter } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import './Footer.css';

function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer mt-auto py-3 bg-dark text-white">
      <Container>
        <Row className="align-items-center">
          <Col md={6} className="text-center text-md-start">
            <p className="mb-0">&copy; {currentYear} Patrick Presslmair. All rights reserved.</p>
            <Link to="/gdpr" className="gdpr-link">GDPR</Link>
          </Col>
          <Col md={6} className="text-center text-md-end">
            <a href="https://www.linkedin.com/in/patrick-presslmair-64257b1ab/" target="_blank" rel="noopener noreferrer" className="me-3">
              <FaLinkedin />
            </a>
            <a href="https://x.com/PikheatP" target="_blank" rel="noopener noreferrer">
              <FaTwitter />
            </a>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;