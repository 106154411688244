import React from 'react';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { FaBriefcase } from 'react-icons/fa';
import styled from 'styled-components';
import PageLayout from '../components/PageLayout';
import '../CustomTimeline.css';

const StyledVerticalTimeline = styled(VerticalTimeline)`
  &::before {
    background: #555;
  }
`;

const StyledVerticalTimelineElement = styled(VerticalTimelineElement)`
  .vertical-timeline-element-content {
    background: #2a2a2a;
    color: #e0e0e0;
    box-shadow: 0 3px 0 #3a3a3a;

    .vertical-timeline-element-date {
      color: #b0b0b0;
    }
  }

  .vertical-timeline-element-content-arrow {
    border-right: 7px solid #2a2a2a;
  }
`;

function Resume() {
  return (
    <PageLayout title="My Resume">
      <StyledVerticalTimeline className="custom-timeline">
        <StyledVerticalTimelineElement
          className="vertical-timeline-element--work"
          date="May 2024 - Present"
          iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
          icon={<FaBriefcase />}
        >
          <h3 className="vertical-timeline-element-title">Customer Support Agent</h3>
          <h4 className="vertical-timeline-element-subtitle">easyname GmbH, Remote</h4>
          <p>Full-time</p>
        </StyledVerticalTimelineElement>

        <StyledVerticalTimelineElement
          className="vertical-timeline-element--work"
          date="March 2023 - April 2024"
          iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
          icon={<FaBriefcase />}
        >
          <h3 className="vertical-timeline-element-title">WordPress Web Developer</h3>
          <h4 className="vertical-timeline-element-subtitle">Seimo, Linz</h4>
          <p>Full-time</p>
        </StyledVerticalTimelineElement>

        <StyledVerticalTimelineElement
          className="vertical-timeline-element--work"
          date="April 2018 - April 2022"
          iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
          icon={<FaBriefcase />}
        >
          <h3 className="vertical-timeline-element-title">Technical Support</h3>
          <h4 className="vertical-timeline-element-subtitle">World4You Internet Services GmbH</h4>
          <p>Full-time</p>
        </StyledVerticalTimelineElement>
      </StyledVerticalTimeline>
    </PageLayout>
  );
}

export default Resume;