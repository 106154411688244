import React from 'react';
import { Row, Col, Image, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import PageLayout from '../components/PageLayout';
import './About.css';
import profileImage from '../assets/ich.webp';

function About() {
  return (
    <PageLayout title="Hello, I'm Patrick">
      <div className="about">
        <Row className="align-items-start flex-grow-1 gx-5">
          <Col md={6} className="d-flex flex-column justify-content-between order-md-1 order-2">
            <div className="about-text-container">
              <div className="about-text">
                <p>
                  I'm a passionate IT Support professional with 5 years of experience in the hosting industry.
                  My journey in this field began with a fascination for technology and a desire to help others solve complex technical problems.
                </p>
                <p>
                  Throughout my career, I've had the opportunity to work on a wide range of hosting-related issues.
                  I'm always eager to learn and stay updated with the latest developments in the IT world.
                </p>
                <p>
                  When I'm not troubleshooting technical issues, you can find me immersed in video games, experimenting with AI technologies, 
                  or spending quality time with my family. I believe that maintaining a healthy work-life balance and pursuing diverse interests helps me bring fresh perspectives to my work in IT support.
                </p>
                <p>
                  I'm always open to new opportunities and collaborations. Feel free to reach out if you'd like to connect or discuss potential projects!
                </p>
              </div>
              <div className="mt-4">
                <Link to="/contact">
                  <Button variant="primary" size="lg">Contact Now!</Button>
                </Link>
              </div>
            </div>
          </Col>
          <Col md={6} className="text-center order-md-2 order-1 mb-4 mb-md-0">
            <div className="image-container">
              <Image 
                src={profileImage}
                alt="Patrick"
                roundedCircle
                fluid
                className="about-image"
              />
            </div>
          </Col>
        </Row>
      </div>
    </PageLayout>
  );
}

export default About;