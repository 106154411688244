// src/components/Navigation.js
import React, { useState } from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../assets/logo700.png';
import './Navigation.css';

function Navigation() {
  const [expanded, setExpanded] = useState(false);
  const navigate = useNavigate();

  const handleNavClick = (path) => {
    setExpanded(false);
    navigate(path);
  };

  return (
    <Navbar bg="dark" variant="dark" expand="lg" expanded={expanded}>
      <Container>
        <Navbar.Brand as={Link} to="/" onClick={() => setExpanded(false)}>
          <img
            src={logo}
            height="20"
            className="d-inline-block align-top"
            alt="Mein Logo"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setExpanded(!expanded)} />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link onClick={() => handleNavClick('/')} className="custom-nav-link">Home</Nav.Link>
            <Nav.Link onClick={() => handleNavClick('/about')} className="custom-nav-link">About Me</Nav.Link>
            <Nav.Link onClick={() => handleNavClick('/resume')} className="custom-nav-link">Resume</Nav.Link>
            <Nav.Link onClick={() => handleNavClick('/contact')} className="custom-nav-link">Contact</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Navigation;