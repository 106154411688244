import React from 'react';
import { Container } from 'react-bootstrap';

const PageLayout = ({ title, children, topSpacing = 'py-5' }) => {
  return (
    <Container className={topSpacing}>
      <h1 className="mb-4">{title}</h1>
      {children}
    </Container>
  );
};

export default PageLayout;